import React, { useState, useEffect } from "react";
import "../../pages/styles/SelectSearch.css";
import "../../pages/styles/NewProduct.css";
import { BotonCancelar3, BotonGuardarLetras } from "../Plantillas/Botones";
import SelectSearch from "react-select-search";
import { isNaN } from "lodash";
import { notificarError, notificarMsg } from "../Almacenes/AlmacenNotify";
import { TRIBUTO } from "../../Global";


export default function Form({ history,match }) {
    const [isLoading, setIsLoading] = useState(true);
    const [insumo, setInsumo] = useState({
        IdInsumo: 0,
        IdAreaProceso: 0,
        IdUnidad: 0,
        IdTipoExistencia: 1,
        IdAfectacionIgv: 1,
        Categoria: "",
        Nombre: "",
        CodigoBarra: "",
        Merma: 0,
        StockMinimo: 0,
    });
    const [tributoSeleccionado, setTributoSeleccionado] = useState(String(TRIBUTO.IGV))
    const [tipoExistencia, setTipoExistencia] = useState([]);
    const [tipoAfectaciones, setTipoAfectaciones] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        const tasks = async () => {
            await Promise.all([
                fetchAreas(),
                fetchTipoExistencias(),
                fetchTipoAfectaciones(),
                fetchUnidades()
            ])

            if (!match.params.insumoId || isNaN(+match.params.insumoId)) {
                return;
            }
            
            await fetchInsumo(+match.params.insumoId);
        }

        tasks().finally(() => setIsLoading(false));
    }, []);

    const fetchInsumo = async (idInsumo) => {
        const req = await fetch(`/api/insumos/${idInsumo}`);
        const response = await req.json();

        setInsumo({ ...response });
    }

    const fetchUnidades = async () => {
        const req = await fetch("/api/productos/unidades");
        const response = await req.json();
        const unidades = response.respuesta;

        const options = unidades.map(o => {
            return {
                name: o.Descripcion,
                value: o.IdUnidad,
            }
        })
        setUnidades(options);
    }

    const fetchTipoAfectaciones = async () => {
        const req = await fetch("/api/productos/afectacion")
        const response = await req.json();
        const afectaciones = response.respuesta;

        const options = afectaciones.filter((afectacion) =>
            afectacion.IdAfectacionIgv <= 7 && afectacion.IdAfectacionIgv !== 3
        ).map(a => {
            return {
                name: a.Descripcion,
                value: a.IdAfectacionIgv,
            }
        })
        setTipoAfectaciones(options);
    }

    const fetchAreas = async () => {
        const req = await fetch("/api/preparacion-productos/areas")
        const response = await req.json();
        const areas = response.areas;

        const options = areas.map(o => {
            return {
                name: o.Nombre,
                value: o.IdArea,
            }
        })
        setAreas(options)
    }

    const fetchTipoExistencias = async () => {
        const req = await fetch("/api/productos/tipoexistencias")
        const response = await req.json();
        const tipos = response.respuesta;

        const options = tipos.map(o => {
            return {
                name: o.Descripcion,
                value: o.IdTipoExistencia,
            }
        })
        setTipoExistencia(options);
    }

    const onCancel = () => {
        history.push("/insumos")
    }

    const onChangeInsumoProp = (e) => {
        console.log(e)
        setInsumo({ ...insumo, [e.target.name]: e.target.value });
    }

    const onSaveChanges = async () => {
        const req = await fetch('/api/insumos/', {
            method: "PUT",
            body: JSON.stringify(insumo),
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (!req.ok) {
            notificarError("Hubo un error al guardar los cambios, verifique que todos los campos estén llenados correctamente.")
            return;
        }

        notificarMsg("Se guardaron correctamente todos los cambios")
        history.push("/insumos")
    }

    if (isLoading) {
        return <div className="container mt-3">  
            <div className="row justify-content-between">
                <div className="col-12 col-sm-6">
                    <BotonCancelar3 type="button" onClick={onCancel} className="col-3 pl-2 pr-2" style={{height:"2.5rem"}}></BotonCancelar3>
                </div>
            </div>
        </div>
    }

    return <div className="container mt-3">  
        <div className="row justify-content-between">
            <div className="col-12 col-sm-6">
                <BotonCancelar3 type="button" onClick={onCancel} className="col-3 pl-2 pr-2" style={{height:"2.5rem"}}></BotonCancelar3>
            </div>

            <div className="col-12">
                <ul className="d-flex justify-content-end nav nav-tabs mt-1" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a
                            className="nav-link active text-secondary"
                            id="pills-insumo-tab"
                            data-toggle="pill"
                            href="#pills-insumo"
                            role="tab"
                            aria-controls="pills-insumo"
                            aria-selected="true"
                        >
                            Detalles del insumo
                        </a>
                    </li>
                </ul>
            </div>

            <div className="tab-content col-12" id="pills-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="pills-insumo"
                    role="tabpanel"
                    aria-labelledby="pills-insumo-tab"
                >
                    <div className="">
                        <div className="">
                            <div className="caja-fondo-blanco-m-2 form-group mt-3">
                                <label className="">Nombre</label>
                                <input
                                    type="text"
                                    name="Nombre"
                                    autoFocus
                                    value={insumo.Nombre}
                                    onChange={onChangeInsumoProp}
                                    className="input__linea"
                                    required
                                />
                            </div>
                            <div className="caja-fondo-blanco-m-2 form-group">
                                <label className="">Categoría</label>
                                <textarea
                                    name="Categoria"
                                    value={insumo.Categoria}
                                    onChange={onChangeInsumoProp}
                                    className="input__linea"
                                    rows="3"
                                />
                            </div>
                            <div className="caja-fondo-blanco-m-2 form-group">
                                <label className="">Código de Barras</label>
                                <input
                                    type="text"
                                    name="CodigoBarra"
                                    value={insumo.CodigoBarra}
                                    onChange={onChangeInsumoProp}
                                    className="input__linea"
                                />
                            </div>
                            <div className="caja-fondo-blanco-m-2 form-group">
                                <label className="">Merma</label>
                                <div class="input-group mb-3">
                                    <input type="number" name="Merma" value={insumo.Merma} onChange={onChangeInsumoProp} class="form-control input__linea" aria-label="Porcentaje de merma"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="caja-fondo-blanco-m-2 form-group">
                                <label className="">Stock Minimo</label>
                                <input
                                    type="number"
                                    name="StockMinimo"
                                    value={insumo.StockMinimo}
                                    onChange={onChangeInsumoProp}
                                    className="input__linea"
                                />
                            </div>
                            <div className="caja-fondo-blanco-m-2">
                                <div className="">
                                    <label>Unidad de medida</label>
                                </div>
                                <div className="">
                                    <SelectSearch
                                        name="IdUnidad"
                                        value={insumo.IdUnidad}
                                        onChange={(e) => onChangeInsumoProp({
                                            target: {
                                                name: "IdUnidad",
                                                value: e.value
                                            }
                                        })}
                                        options={unidades}
                                        className="select-search-box"
                                        placeholder="Selecciona"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="caja-fondo-blanco-m-2">
                                <div className="">
                                    <label>Area</label>
                                </div>
                                <div className="">
                                    <SelectSearch
                                        name="IdAreaProceso"
                                        value={insumo.IdAreaProceso}
                                        onChange={(e) => onChangeInsumoProp({
                                            target: {
                                                name: "IdAreaProceso",
                                                value: e.value
                                            }
                                        })}
                                        options={areas}
                                        className="select-search-box"
                                        placeholder="Selecciona"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="caja-fondo-blanco-m-2">
                                <div className="">
                                    <label>Tipo existencia</label>
                                </div>
                                <div className="">
                                    <SelectSearch
                                        name="IdTipoExistencia"
                                        value={insumo.IdTipoExistencia}
                                        onChange={(e) => onChangeInsumoProp({
                                            target: {
                                                name: "IdTipoExistencia",
                                                value: e.value
                                            }
                                        })}
                                        options={tipoExistencia}
                                        className="select-search-box"
                                        placeholder="Selecciona"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="caja-fondo-blanco-m-2">
                                <div className="col-12 row">
                                    <label>Tipo de Tributos</label>
                                    <div className="input-group col-6">
                                        <div className="custom-control custom-radio  mr-3">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                value={TRIBUTO.IGV}
                                                id={"tributo" + TRIBUTO.IGV}
                                                checked={
                                                    tributoSeleccionado ===
                                                    String(TRIBUTO.IGV)
                                                }
                                                onClick={() => setTributoSeleccionado(String(TRIBUTO.IGV))}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={"tributo" + TRIBUTO.IGV}
                                            >
                                                IGV
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio mr-3">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                value={TRIBUTO.IVAP}
                                                id={"tributo" + TRIBUTO.IVAP}
                                                name="groupOfDefaultRadios"
                                                checked={
                                                    tributoSeleccionado ===
                                                    String(TRIBUTO.IVAP)
                                                }
                                                onClick={() => setTributoSeleccionado(String(TRIBUTO.IVAP))}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={"tributo" + TRIBUTO.IVAP}
                                            >
                                                IVAP
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio mr-3">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                value={TRIBUTO.EXONERADO}
                                                id={"tributo" + TRIBUTO.EXONERADO}
                                                name="groupOfDefaultRadios"
                                                checked={
                                                    tributoSeleccionado ===
                                                    String(TRIBUTO.EXONERADO)
                                                }
                                                onClick={() => setTributoSeleccionado(String(TRIBUTO.EXONERADO))}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={"tributo" + TRIBUTO.EXONERADO}
                                            >
                                                EXO
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio mr-3">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                value={TRIBUTO.INAFECTO}
                                                id={"tributo" + TRIBUTO.INAFECTO}
                                                name="groupOfDefaultRadios"
                                                checked={
                                                    tributoSeleccionado ===
                                                    String(TRIBUTO.INAFECTO)
                                                }
                                                onClick={() => setTributoSeleccionado(String(TRIBUTO.INAFECTO))}                                 
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={"tributo" + TRIBUTO.INAFECTO}
                                            >
                                                INA
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="">
                                        <div className="">
                                            <SelectSearch
                                                name="IdAfectacionIgv"
                                                value={insumo.IdAfectacionIgv}
                                                onChange={(e) => onChangeInsumoProp({
                                                    target: {
                                                        name: "IdAfectacionIgv",
                                                        value: e.value
                                                    }
                                                })}
                                                options={tipoAfectaciones}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div align="right">
                        <BotonGuardarLetras
                            onClick={onSaveChanges}
                            className="mb-3 mr-3"
                        ></BotonGuardarLetras>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
