import React from "react";
import "./Estilos.css";
import LinkButton from "../LinkButton";

const botonGenerator = (defaultClasses, defaultChildren=undefined, title=undefined) => {

  return ({children, className: extraClasses, ...props}) => {

    // classes
    const classes = [];
    if (extraClasses)   classes.push(extraClasses);
    if (defaultClasses) classes.push(defaultClasses);
    const className = classes.join(' ');

    // button props
    const buttonProps = {className, title, ...props};

    return (
      (props.to)
        ? (
            <LinkButton {...buttonProps} >
              {defaultChildren} {children}
            </LinkButton>
          )
        : (
            <button {...buttonProps}>
              {defaultChildren} {children}
            </button>
          )
    )
  }
}

export const BotonGuardar = botonGenerator(
                                            "boton__verdeclaro",
                                            (<i className="far fa-save" />),
                                            "Guardar"
                                          );
export const BotonGuardarLetras = botonGenerator(
                                                  "boton__verdeClaro--letras",
                                                  (<span>Guardar</span>),
                                                  "Guardar"
                                                );

export const BotonCrearLetras = botonGenerator(
                                                  "boton__verdeClaro--letras",
                                                  (<span>Crear</span>),
                                                  "Crear"
                                                );                                                

export const BotonLetras = botonGenerator("btn btn-verde-mfp");

export const BotonLetrasVerdeOscuro = botonGenerator("boton__verdeOscuro--letras");

export const BotonLetrasAmarillo = botonGenerator("boton__amarillo--letras");

export const BotonLetrasRojo = botonGenerator("boton__Rojo--letras");

export const BotonCancelar = botonGenerator (
                                              "boton__pequeno--y",
                                              (<i className="fas fa-arrow-left"/>),
                                              "Cancelar"
                                            );                                        
export const BotonCancelar2 = botonGenerator (
                                              "boton__pequeno--y2",
                                              (<i className="fas fa-arrow-left"/>),
                                              "Cancelar"
                                            );
export const BotonCancelar3 = botonGenerator (
                                              "btn-verde-mfp",
                                              (<a className="atras-text"/>),
                                              "Atras"
                                            );
export const BotonNuevaPreventa = botonGenerator("btn-verde-mfp",(<a className="nueva-preventa-text"/>));

export const BotonGestionPreventas = botonGenerator("btn-verde-mfp",(<a className="gestion-preventas-text"/>));

export const BotonCancelarLetras = botonGenerator (
                                                    "boton__amarillo--letras",
                                                    (<span>Cancelar</span>),
                                                    "Cancelar"
                                                  );

export const BotonCancelarSinLetras = botonGenerator (
                                                    "boton__amarillo--letras",
                                                    (<span></span>),

                                                  );

export const BotonCerrarCajaLetras = botonGenerator (
                                                    "boton__Rojo--letras",
                                                    (<span>Cerrar Caja</span>),
                                                    "Cancelar"
                                                  );

// export const BotonBuscar = botonGenerator (
//                                             "boton__pequeno--g",
//                                             (<i className="fas fa-download"/>),
//                                             "Buscar"
//                                           );

export const BotonBuscar = botonGenerator (
                                            "btn-verde-mfp",
                                            (<a className="buscar-text-general"/>)
                                          );

export const BotonBuscarCliente = botonGenerator (
                                            "btn-verde-mfp-letra-normal",(<a className="seleccionar-cliente-text"/>)
                                          );

//  export const BotonEditar = botonGenerator (
//                                              "boton__outline-pequeno--y",
//                                              (<i className="fas fa-edit"/>),
//                                              "Editar"
//                                            );
export const BotonEditar = botonGenerator ("btn-naranja-mfp-letra-normal",(<a className="editar-text"/>)
);

export const BotonEditarCliente = botonGenerator ("btn-verde-mfp-letra-normal",(<a className="editar-cliente-text"/>),"Editar");

export const BotonPDFList = botonGenerator (
                                            "boton__Rojo--Pequeño",
                                            (<i className="fas fa-file-pdf"/>)
                                          );


// export const BotonPDF = botonGenerator("boton__Rojo",(<i className="fas fa-file-pdf"/>));                                          
export const BotonPDF = botonGenerator("btn btn-outline-primary btn-pdf-mfp",(<a className="">Descargar PDF</a>));

// export const BotonExcel = botonGenerator("boton__verdeOscuro",(<i className="fas fa-file-excel"/>));
export const BotonExcel = botonGenerator("btn btn-outline-primary btn-excel-mfp",(<a className="">Descargar Excel</a>));
export const BotonExcelDos = botonGenerator("btn-verde-mfp",(<a className="text-descargar"/>));

// export const BotonAdd = botonGenerator("boton__verdeClaro",(<i className="fas fa-plus"/>));
export const BotonAdd = botonGenerator("boton__verdeClaro",(<a className=""/>));
export const BotonAddDoc = botonGenerator("btn-verde-mfp",(<a className=" buscar-text-doc"/>));
export const BotonRegistrar = botonGenerator("btn-verde-mfp",(<a className="registrar-text"/>));
export const BotonAddAlmacen = botonGenerator("btn-verde-mfp",(<a className="almacen-text"/>));
export const BotonAddAgregar = botonGenerator("btn-verde-mfp",(<a className="agregar-text"/>));
export const BotonAddOC = botonGenerator("btn-verde-mfp",(<a className="agregar-oc-text"/>));

export const BotonAddCliente = botonGenerator("boton__verdeClaro",(<i className="fas fa-user-plus"/>));
export const BotonAddCliente2 = botonGenerator("btn-verde-mfp-letra-normal",(<a className="agregar-cliente-text"/>));
export const BotonAddEmpleado = botonGenerator("btn-verde-mfp-letra-normal",(<a className="agregar-empleado-text"></a>));

export const BotonDelete = botonGenerator("btn-anular-letra-normal",(<a className="eliminar-text"/>));

export const BotonMenos = botonGenerator("boton__Rojo",(<i className="fas fa-minus"/>));

export const BotonRemove = botonGenerator("boton__Rojo",(<i className="fas fa-trash-alt"/>));

export const BotonDown = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-arrow-down"/>));

export const BotonUp = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-arrow-up"/>));

export const BotonAddPequeño = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-plus"/>));
export const BotonAddItem = botonGenerator("btn-verde-mfp-letra-normal",(<a className="item-text"/>));


export const BotonMenosPequeño = botonGenerator("boton__Rojo--Pequeño",(<i className="fas fa-minus"/>));

export const BotonRemovePequeño = botonGenerator("boton__Rojo--Pequeño",(<i className="fas fa-trash-alt"/>));

export const BotonAddProveedor = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-user-plus"/>));
export const BotonAddProveedorDos = botonGenerator("btn-verde-mfp-letra-normal",(<a className="proveedor-text"/>));

export const BotonDeletePequeno = botonGenerator("boton__outline-maspequeno--r",(<i className="fas fa-trash-alt"/>));
export const BotonEditPequeno = botonGenerator("boton__outline-maspequeno--y",(<i className="fas fa-pencil-alt" />));

export const BotonCheckPequeno = botonGenerator("boton__outline-maspequeno--g",(<i className="fas fa-check" />));
export const BotonCancelPequeno = botonGenerator("boton__outline-maspequeno--r",(<i className="fas fa-times" />));

// export const BotonProcesarPequeno = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-check-square"/>));
export const BotonProcesarPequeno = botonGenerator("btn-verde-mfp-letra-normal",(<a className="procesar-text"/>));

// export const BotonPDFpequeno = botonGenerator("boton__outline-pequeno--r",(<i className="fas fa-file-pdf"/>));
export const BotonPDFpequeno = botonGenerator("btn-pdf-mfp-letra-normal",(<a className="pdf-text"/>));
export const BotonTicket = botonGenerator("btn-pdf-mfp-letra-normal",(<a className="">Descargar Ticket</a>));
export const BotonCashRegister = botonGenerator("boton__outline-pequeno--r",(<i className="fas fa-cash-register"></i>));
export const BotonCashRegisterAperturar = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-cash-register"></i>));
export const BotonAperturar = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-lock-open"></i>))
export const BotonAperturarCaja = botonGenerator("btn-verde-mfp-letra-normal",(<a className="aperturar-caja-text"></a>))

export const BotonInformacion = botonGenerator("boton__outline-pequeno--y",(<i className="fas fa-info"></i>))

export const BotonMonedas = botonGenerator("btn-verde-mfp",(<a className="precios-text"></a>));
export const BotonVehiculos = botonGenerator("btn-verde-mfp",(<a className="vehiculos-text"></a>));
export const BotonConductores = botonGenerator("btn-verde-mfp",(<a className="conductores-text"></a>));
export const BotonCheck = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-check-square"></i>));
export const BotonConfiguracion = botonGenerator("btn-verde-mfp-letra-normal",(<a className="config-text"></a>));

export const BotonSearchCliente = botonGenerator("boton__verdeClaro",(<i className="fas fa-search"/>));
export const BotonSearchCliente2 = botonGenerator("btn-verde-mfp-letra-normal",(<a className="buscar-cliente-text"/>));

export const BotonSearchProveedor = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-search"/>));
export const BotonSearchProveedorDos = botonGenerator("btn-verde-mfp-letra-normal",(<a className="buscar-text"/>));


export const BotonPesar = botonGenerator(
  "boton__verdeClaro--Pequeño",
  (<i class="fas fa-weight"/>),
);
