import React from "react";
import {BotonAperturarCaja} from "../Plantillas/Botones";
import Notify from "../Notify";
import _ from "lodash";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class TurnoApertura extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cajas: [],
            dataC: {Cajas: []},
            IdCaja: 0,
            GuardarEnAperturarCaja: false,
            ObservacionApertura: '',
            MontoInicial: 0,
            Observacion: '',
            isLoading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.montoAperturaVacio = this.montoAperturaVacio.bind(this);
        this.AperturarTurno = this.AperturarTurno.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.obtenerCajas();
        this.setState({isLoading: false})
    }

    async obtenerCajas() {
        try {
            const response = await fetch(`/api/cajas/cajas/SinTurnos`);
            const data = await response.json();
            if (data.Cajas.length !== 0)
                this.setState({
                    dataC: data,
                    IdCaja: data.Cajas[0].IdCaja,
                    GuardarEnAperturarCaja: false
                })
            else
                Notify("Registre una caja", "warning", 1500);
        } catch (error) {
            notificarError("Hubo un error al momento de obtener las cajas.")
        }
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }

    montoAperturaVacio(e) {
        if (this.state.MontoInicial === '') {
            this.setState({
                MontoInicial: 0
            })
        }
    }


    async AperturarTurno(e) {
        e.preventDefault();
        try {
            const res = await fetch(`/api/cajas/aperturar/turno`, {
                method: 'POST',
                body: JSON.stringify({
                    IdCaja: this.state.IdCaja,
                    MontoInicial: this.state.MontoInicial,
                    Observacion: this.state.ObservacionApertura
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            const rpta = await res.json()
            window.location.reload();
        } catch (err) {
            console.log("err ", err)
            notificarError("Ha ocurrido un error al momento de aperturar turno")
        }
    }

    render() {
        return (
            <section className="modal-aperturar-caja">
                <form>
                    <div className="">
                        <div className="mt-4">
                            <b className="ml-2 mt-4 mr-2">Caja: </b>
                            <div className="input__Almacen mr-2 ml-2 caja-fondo-blanco-modal">
                                <select required className="input_sin_linea" name="IdCaja"
                                        onChange={this.handleChange} value={this.state.IdCaja}>{
                                    this.state.dataC.Cajas.map((obj) => {
                                        return <option key={obj.IdCaja} value={obj.IdCaja}>{obj.Nombre}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="mt-3">
                            <b className="ml-2 mt-4 mr-2">Monto Apertura: </b>
                            <div className="input__Almacen mr-2 ml-2 caja-fondo-blanco-modal">
                                <div className="row add-pad">
                                    <div className="mt-3 ml-2">
                                        S/.
                                    </div>
                                    <input
                                        name="MontoInicial"
                                        className="input_sin_linea mt-2 ml-1"
                                        type="number" step="any" min="0"
                                        value={this.state.MontoInicial}
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleChange}
                                        onBlur={this.montoAperturaVacio}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 ">
                            <b className="ml-2 mt-4 mr-2">Observación: </b>
                            <div className="input__Almacen mr-2 ml-2 caja-fondo-blanco-modal">
                                <input
                                    type="text"
                                    name="ObservacionApertura"
                                    className="input_sin_linea mt-2"
                                    value={this.state.ObservacionApertura}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div align="center" className="mb-2">
                            <BotonAperturarCaja title="Aperturar Caja"
                                            className="mt-3 mb-3 boton-aperturar"
                                            type="button"
                                            onClick={_.debounce((e) => {
                                                this.AperturarTurno(e)
                                            }, 3000)}/>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}
