import React, { useEffect, useState, useMemo } from "react";
import "../../pages/styles/Productos.css";
import "jspdf-autotable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import LinkButton from "../LinkButton";
import swal from "sweetalert";
import { notificarError, notificarMsg } from "../Almacenes/AlmacenNotify";
import ReactExport from "react-data-export";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


function getDataExportExcel(dataSet) {
    const data = dataSet.filter(x => x);
    return [
        {
            columns: [
                {
                    title: "Código",
                    width: {wpx: 80}
                },
                {
                    title: "Código de barras",
                    width: {wpx: 80}
                },
                {
                    title: "Nombre",
                    width: {wpx: 200}
                },
                {
                    title: "Unidad de Medida",
                    width: {wpx: 170}
                },
                {
                    title: "Categoria",
                    width: {wpx: 170}
                },
                {
                    title: "Merma (%)",
                    width: {wpx: 80}
                },
                {
                    title: "Stock minimo",
                    width: {wpx: 80}
                },
                {
                    title: "Tipo de Afectación IGV",
                    width: {wpx: 250}
                },
            ],

            data: data.map(row => {
                return [
                    {
                        value: row.Codigo,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CodigoBarra || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Nombre,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Unidad,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Categoria || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Merma || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.StockMinimo || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.AfectacionIgv || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                ];
            })
        }
    ];
}


export default function List() {
    const [insumos, setInsumos] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [excelRows, setExcelRows] = useState([]);

    const paginatedData = useMemo(() => {
        const start = page * pageSize;
        const end = start + pageSize;
        return insumos.slice(start, end);
      }, [insumos, page, pageSize]);

    useEffect(() => {
        fetchInsumos();
    }, [])

    const fetchInsumos = async () => {
        const req = await fetch(`/api/insumos/`);
        const response = await req.json();

        const dataExport = getDataExportExcel(response.insumos);
        setExcelRows(dataExport)
        setInsumos([ ...response.insumos]);
    }

    const eliminarInsumo = async (id) => {
        const req = await fetch(`/api/insumos/${id}`, {
            method: 'DELETE'
        });

        if (req.ok) {
            notificarMsg("Se eliminó el insumo correctamente");
        } else {
            notificarError("No se pudo eliminar el insumo");
        }

        fetchInsumos();
    }

    const handleDelete = async (IdInsumo) => {
        const willDelete = await swal({
            title: "¿Está seguro?",
            text: "¿Desea borrar este insumo?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false
        });

        if (willDelete)
            eliminarInsumo(IdInsumo);
    }

    const generarExcel = async () => {
        const dataExport = getDataExportExcel(insumos);
        setExcelRows(dataExport)
    }

    return <>
        <section className="ventas-fpay-section-vh pt-5">
            <div className="container">
                <div className="mt-1 mb-3">
                    <div align="right" className="mb-1 mr-3">
                        <LinkButton to="/insumos/form" className="btn-verde-mfp btn-verde-mfp-letra-normal py-2">
                            <span className="">Registrar insumo</span>
                        </LinkButton>
                        <ExcelFile
                            element={
                                <button className="boton__verdeOscuro"><i className="fas fa-file-excel"></i></button>
                            }
                            filename="ReporteInsumos"
                        >
                            <ExcelSheet
                                dataSet={excelRows}
                                name="Insumos"
                            >
                            </ExcelSheet>
                        </ExcelFile>
                    </div>

                </div>
                {
                    <ReactTable
                        id="table-productos-to-xls"
                        data={paginatedData}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        manual={true}
                        filterable={true}
                        sortable={true}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Opciones",
                                        id: "id",
                                        width: 220,
                                        accessor: d => d.IdInsumo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["id"]
                                            }),
                                        Cell: f => {
                                            return (
                                                <div align="center">
                                                    <LinkButton
                                                        to={`/insumos/form/${f.value}`}
                                                        className="btn-naranja-mfp"
                                                        style={{fontSize: "16px"}}
                                                    >
                                                        {<a className="">Editar</a>}
                                                    </LinkButton>
                                                    <button
                                                        id={f.value}
                                                        value={f.original.IdInsumo}
                                                        type="button"
                                                        className="btn-anular ml-1"
                                                        style={{fontSize: "16px"}}
                                                        onClick={() => handleDelete(f.original.IdInsumo)}
                                                    >
                                                        {<a className="">Borrar</a>}
                                                    </button>
                                                </div>
                                            );
                                        },

                                        filterAll: true
                                    },
                                    {
                                        Header: "Código",
                                        id: "codigo",
                                        width: 90,
                                        accessor: d => d.Codigo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Codigo"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "CodigoBarra",
                                        id: "CodigoBarra",
                                        width: 110,
                                        accessor: d => d.CodigoBarra,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["CodigoBarra"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Nombre",
                                        id: "Nombre",
                                        width: 250,
                                        accessor: d => d.Nombre,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Nombre"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Unidad de Medida",
                                        id: "Unidad",
                                        width: 170,
                                        accessor: d => d.Unidad,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Unidad"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Categoria",
                                        id: "Categoria",
                                        width: 170,
                                        accessor: d => d.Categoria,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Categoria"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Stock minimo",
                                        id: "StockMinimo",
                                        accessor: d => d.StockMinimo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["StockMinimo"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Merma",
                                        id: "Merma",
                                        accessor: d => d.Merma,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Merma"]
                                            }),
                                        Cell: f => {
                                            return `${f.value} %`;
                                        },
                                        filterAll: true
                                    },
                                    {
                                        Header: "Tipo de Afectación IGV",
                                        id: "AfectacionIgv",
                                        width: 250,
                                        accessor: d => d.AfectacionIgv,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["AfectacionIgv"]
                                            }),
                                        filterAll: true
                                    },
                                ]
                            }
                        ]}
                        defaultPageSize={pageSize}
                        pages={Math.ceil(insumos.length / pageSize)}
                        onPageChange={(pageIndex) => setPage(pageIndex)}
                        onPageSizeChange={(newPageSize, pageIndex) => {
                            setPageSize(newPageSize);
                            setPage(pageIndex);
                        }}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                }
            </div>
        </section>
    </>
};
